import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "sampleInfo" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { style: {"margin":"15px"} }
const _hoisted_5 = {
  key: 0,
  style: {"margin":"15px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.show) = $event)),
      position: "bottom",
      closeable: "",
      "close-icon-position": "top-right",
      teleport: "#app",
      style: { width: '100%', height: '70%' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_cell_group, { title: "申请单信息" }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell, {
              title: "样品编码",
              value: _ctx.inconWorkForm.code
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "样品名称",
              value: _ctx.inconWorkForm.name
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "不符合类型",
              value: _ctx.inconWorkForm.inconType
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "不符合来源",
              value: _ctx.inconWorkForm.iconSource
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "不符合描述",
              value: _ctx.inconWorkForm.iconDes
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "发现机构",
              value: _ctx.inconWorkForm.findOrgName
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "发现部门",
              value: _ctx.inconWorkForm.findDeptName
            }, null, 8, ["value"]),
            (_ctx.inconWorkForm.statusEval==1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_van_cell, {
                    title: "不符合条款",
                    value: _ctx.inconWorkForm.iconBasis
                  }, null, 8, ["value"]),
                  _createVNode(_component_van_cell, {
                    title: "严重性",
                    value: _ctx.inconWorkForm.seriousness
                  }, null, 8, ["value"]),
                  _createVNode(_component_van_cell, {
                    title: "可接受程度",
                    value: _ctx.inconWorkForm.acceptabilityGrade
                  }, null, 8, ["value"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_van_field, {
                    modelValue: _ctx.inconWorkForm.iconBasis,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inconWorkForm.iconBasis) = $event)),
                    label: "不符合条款",
                    placeholder: "请输入条款编号",
                    required: "",
                    rules: [{ required: true, message: '请输入条款编号' }],
                    "input-align": "right"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_van_field, {
                    modelValue: _ctx.inconWorkForm.seriousness,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inconWorkForm.seriousness) = $event)),
                    "is-link": "",
                    readonly: "",
                    label: "严重性",
                    placeholder: "选择严重程度",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSeriousnessPicker = true)),
                    "input-align": "right"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_van_popup, {
                    show: _ctx.showSeriousnessPicker,
                    "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSeriousnessPicker) = $event)),
                    round: "",
                    position: "bottom"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_picker, {
                        columns: _ctx.seriousnessList,
                        teleport: "body",
                        "value-key": "name",
                        onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSeriousnessPicker = false)),
                        onConfirm: _ctx.onConfirm
                      }, null, 8, ["columns", "onConfirm"])
                    ]),
                    _: 1
                  }, 8, ["show"]),
                  _createVNode(_component_van_field, {
                    modelValue: _ctx.inconWorkForm.acceptabilityGrade,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inconWorkForm.acceptabilityGrade) = $event)),
                    "is-link": "",
                    readonly: "",
                    label: "可接受程度",
                    placeholder: "请选择可接受程度",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showAcceptPicker=true)),
                    "input-align": "right"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_van_popup, {
                    show: _ctx.showAcceptPicker,
                    "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showAcceptPicker) = $event)),
                    round: "",
                    position: "bottom"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_picker, {
                        columns: _ctx.acceptableGradeList,
                        teleport: "body",
                        "value-key": "name",
                        onCancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showAcceptPicker = false)),
                        onConfirm: _ctx.onConfirm2
                      }, null, 8, ["columns", "onConfirm"])
                    ]),
                    _: 1
                  }, 8, ["show"])
                ]))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.inconWorkForm.statusEval==0)
            ? (_openBlock(), _createBlock(_component_van_button, {
                key: 0,
                round: "",
                block: "",
                type: "primary",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.submitForm('inconWorkForm')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("评价")
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_van_button, {
                key: 1,
                round: "",
                block: "",
                type: "primary",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.concelElva('inconWorkForm')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("取消评价")
                ]),
                _: 1
              }))
        ]),
        (_ctx.inconWorkForm.statusEval==0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_van_button, {
                round: "",
                block: "",
                type: "default",
                onClick: _ctx.cancelForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode("取消")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}