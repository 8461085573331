
import { Options, Vue } from "vue-class-component";
import { collectStatus, eCollectStatus } from "@/middlewares/enum";
import NoEvalInconWork from "@/views/qualityManage/NoEvalInconWork.vue";
import EvaledInconWork from "@/views/qualityManage/EvaledInconWork.vue";
@Options({
  components: {
    NoEvalInconWork,
    EvaledInconWork,
  },
})
export default class InconWorkList extends Vue {
  public created() {
    (window as any).callJS2 = (msg: any) => {
      //和flutter一样的方法
      return this.callJS(msg);
    };
    if (this.$store.state.userInfo) {
      localStorage.setItem("token", this.$store.state.userInfo.token);
    }
  }
  private tabsChange(tab: any) {
    if (tab === 1) {
      const temp: any = this.$refs['NoEvalInconWork'];
      temp.refreshing = true;
      temp.findCovidCollectList();
    } else {
      const temp: any = this.$refs['EvaledInconWork'];
      temp.refreshing = true;
      temp.findCovidCollectList();
    }
  }
  private callJS(message: any) {
    return "call js ok";
  }
}
