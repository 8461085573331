import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "inconWorkList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoEvalInconWork = _resolveComponent("NoEvalInconWork")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_EvaledInconWork = _resolveComponent("EvaledInconWork")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_tabs, {
      sticky: "",
      "offset-top": "0px",
      onClick: _ctx.tabsChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, { title: "未评价" }, {
          default: _withCtx(() => [
            _createVNode(_component_NoEvalInconWork, { ref: "NoEvalInconWork" }, null, 512)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "已评价" }, {
          default: _withCtx(() => [
            _createVNode(_component_EvaledInconWork, { ref: "EvaledInconWork" }, null, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}