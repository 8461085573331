
import { Options, Vue } from "vue-class-component";
import qs from "qs";
import moment from "moment";
import { Notify, Toast } from "vant";
import { Sleep } from "../../middlewares/common";
import { envTypes, SampleModes } from "../../middlewares/enum";

export default class SampleInfo extends Vue {
  private showSeriousnessPicker = false;
  private showAcceptPicker = false;
  private show = false;
  private sampleModes = SampleModes;
  private seriousnessList = [];
  private acceptableGradeList = [];
  private goodForm = {
    idx: null, //序号：编辑时使用
    // envType: [], //环境类型
    // otherType: "", //其他类型
    // specObj: "", //采集对象
    specName: "", //样品名称
  };
  private sampleForm = {};
  private showSampleInfo(item: any) {
    this.getInconformityWorkById(item.id);
    this.sampleForm = item;
    this.show = true;
  }
  private inconWorkForm = {
    idx: null,
    id: '',
    name: '',
    inconType: '',
    iconSource: '',
    iconDes: '',
    iconBasis: '',
    seriousness: '',
    acceptabilityGrade: '',
    status: '0',
    inconSurv: '',
    findDeptId: '',
    findDeptName: '',
    findOrgId: '',
    findOrgName: '',
    resposibilityOrgId: '',
    resposibilityDeptId: '',
    createTime: '',
    editTime: '',
    delFlag: '',
    resposibilityPersonId: '',
    correct: '',
    rectification: '',
    workStatus: '',
    measureCode: '',
    effectConfirmation: '',
    statusSurv: '0',
    statusEval: '0',
    creator: '',
    editor: '',
    rectificationStatus: '',
    rectificationAck: '',
    type: '0',
  };


  // private columns = [
  //   { value: 0, text: '轻度不符合' },
  //   { value: 1, text: '一般不符合' },
  //   { value: 2, text: '严重不符合' }
  // ];
  public created() {
    const dictTreeTypeCodes = ['seriousness', 'acceptableGrade'];
    this.getDictItemsList(dictTreeTypeCodes.join(','));

  }

  private getDictItemsList(codes: string) {
    const url = '/api/sys/getDictValueByTypes';
    this.axios.get(url, { params: { code: codes } }).then((res: any) => {
      if (res.status.code !== 1) {
        this.$toast.fail(res.status.msg);
      } else {
        const dictTreeTypeItems = res.data;
        //获取严重性字典
        const seriousnessType = dictTreeTypeItems.find((item: { code: string; }) => item.code === 'seriousness');
        this.seriousnessList = seriousnessType.itemList;
        //获取可接受程度
        const acceptableGradeType = dictTreeTypeItems.find((item: { code: string; }) => item.code === 'acceptableGrade');
        this.acceptableGradeList = acceptableGradeType.itemList;

      }
    })
      .catch(err => {
        this.$toast.fail(err);
      });
  }

  private getInconformityWorkById(id: any) {
    const url = "/api/lims/inconformityWork/getInconformityWorkById";
    this.axios
      .get(url, { params: { id } })
      .then(async (res: any) => {
        if (res.status.code !== 1) {
          //this.$alert(res.status.msg, '提示');
        } else {
          const data = res.data;
          this.inconWorkForm = data;
        }
      })
      .catch((err) => {
        //this.$alert(err, '提示');
      });
  }

  private formatDateToDays(time: any) {
    if (time) {
      return moment(time).format("YYYY-MM-DD");
    } else {
      return "";
    }
  }
  private getSampleModeDesc(scope: any) {
    for (const item of this.sampleModes) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }
  private submitForm(formName: any) {
    // const sltSampleList: any = [];
    // sltSampleList.push(this.sampleForm);

    // this.$dialog
    //   .confirm({
    //     title: "确认",
    //     message: "确认要接收样品么？",
    //   })
    //   .then(() => {
    //     const obj: any = {
    //       sampleList: sltSampleList,
    //     };
    //     obj.receiptTime = moment().format("YYYY-MM-DD");
    //     const url = '/api/lims/inconformityWork/editInconformityWork';;
    //     this.axios
    //       .post(url, obj)
    //       .then((res: any) => {
    //         if (res.status.code === 1) {
    //           Toast("接收成功");
    //           this.$emit("doSearch");
    //           this.show = false;
    //         } else {
    //           Notify(res.status.msg);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   })
    //   .catch(() => {
    //     console.log("cancel delete");
    //   });
    const el: any = this.$refs[formName];

    this.inconWorkForm.statusEval = '1';
    if (this.inconWorkForm.statusEval === '1' && this.inconWorkForm.statusSurv === '1') {
      this.inconWorkForm.status = '10';
    }
    let url = '';
    let obj = new Object();
    obj = {
      id: this.inconWorkForm.id,
      name: this.inconWorkForm.name,
      inconType: this.inconWorkForm.inconType,
      iconSource: this.inconWorkForm.iconSource,
      iconDes: this.inconWorkForm.iconDes,
      iconBasis: this.inconWorkForm.iconBasis,
      seriousness: this.inconWorkForm.seriousness,
      acceptabilityGrade: this.inconWorkForm.acceptabilityGrade,
      status: this.inconWorkForm.status,
      inconSurv: this.inconWorkForm.inconSurv,
      findDeptId: this.inconWorkForm.findDeptId,
      findDeptName: this.inconWorkForm.findDeptName,
      findOrgName: this.inconWorkForm.findOrgName,
      findOrgId: this.inconWorkForm.findOrgId,
      resposibilityOrgId: this.inconWorkForm.resposibilityOrgId,
      correct: this.inconWorkForm.correct,
      rectification: this.inconWorkForm.rectification,
      workStatus: this.inconWorkForm.workStatus,
      measureCode: this.inconWorkForm.measureCode,
      effectConfirmation: this.inconWorkForm.effectConfirmation,
      statusSurv: this.inconWorkForm.statusSurv,
      statusEval: this.inconWorkForm.statusEval,
      creator: this.inconWorkForm.creator,
      editor: this.inconWorkForm.editor,
      rectificationStatus: this.inconWorkForm.rectificationStatus,
      rectificationAck: this.inconWorkForm.rectificationAck,
    };
    url = '/api/lims/inconformityWork/editInconformityWork';

    this.axios.post(url, obj)
      .then((res: any) => {
        if (res.status.code !== 1) {
          Notify(res.status.msg);
        } else {
          Toast("评价成功");
          this.$emit("doSearch");
          this.show = false;
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  private concelElva(formName:any) {
    const el: any = this.$refs[formName];
    this.inconWorkForm.statusEval = '0';
    this.inconWorkForm.status = '5';

      let url = '';
      let obj = new Object();
      obj = {
        id: this.inconWorkForm.id,
        name: this.inconWorkForm.name,
        inconType: this.inconWorkForm.inconType,
        iconSource: this.inconWorkForm.iconSource,
        iconDes: this.inconWorkForm.iconDes,
        iconBasis:'',
        seriousness:'',
        acceptabilityGrade:'',
        status: this.inconWorkForm.status,
        inconSurv: this.inconWorkForm.inconSurv,
        findDeptId: this.inconWorkForm.findDeptId,
        findDeptName: this.inconWorkForm.findDeptName,
        findOrgName: this.inconWorkForm.findOrgName,
        findOrgId: this.inconWorkForm.findOrgId,
        resposibilityOrgId: this.inconWorkForm.resposibilityOrgId,
        correct: this.inconWorkForm.correct,
        rectification: this.inconWorkForm.rectification,
        workStatus: this.inconWorkForm.workStatus,
        measureCode: this.inconWorkForm.measureCode,
        effectConfirmation: this.inconWorkForm.effectConfirmation,
        statusSurv: this.inconWorkForm.statusSurv,
        statusEval: this.inconWorkForm.statusEval,
        creator: this.inconWorkForm.creator,
        editor: this.inconWorkForm.editor,
        rectificationStatus: this.inconWorkForm.rectificationStatus,
        rectificationAck: this.inconWorkForm.rectificationAck,
      };
      url = '/api/lims/inconformityWork/editInconformityWork';

      this.axios.post(url, obj)
        .then((res: any) => {
          if (res.status.code !== 1) {
            Notify(res.status.msg);
          } else {
            Toast('取消成功');
            this.$emit("doSearch1");
            this.show = false;
          }
        }).catch((err) => {
          console.log(err);
        });
  }

  private cancelForm() {
    this.goodForm = {
      idx: null, //序号：编辑时使用
      //   envType: [], //环境类型
      //   otherType: "", //其他类型
      //   specObj: "", //采集对象
      specName: "", //样品名称
    };
    this.show = false;
  }

  private onConfirm(seriousnessList: any) {
    this.inconWorkForm.seriousness = seriousnessList.name;
    this.showSeriousnessPicker = false;
  }

  private onConfirm2(acceptableGradeList: any) {
    this.inconWorkForm.acceptabilityGrade = acceptableGradeList.name;
    this.showAcceptPicker = false;
  }

}
